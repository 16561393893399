



























































































































































/* eslint-disable */
import { Vue, Component, Watch } from "vue-property-decorator";
import { api_group, create_group } from "@/api";
import { More } from "@/mixin/more";
import { OtherModule } from "@/store/other";
import { ElForm } from "element-ui/types/form";
import { Encryption } from "@/util/encryption";
import { UserModule } from "@/store/user";
interface user {
  id: number;
  value: string;
  username: string;
}
@Component
export default class extends More(api_group.get_group) {
  step = false;

  rules = {
    chapter: [{ required: true, message: "섹션명을 선택해주세요" }],
  };
  info: create_group = {
    type: 1,
    subject: "",
    chapter: "",
  };

  subject = [];

  options = [];

  filter = {
    type: 1,
  };

  async show_step() {
    this.subject = await api_group.get_user_course();
    this.step = true;
  }

  async to_check_user(id: number, length: number, ele:any) {
    let that = this;
    if (length < 2) {
      await api_group.add_group({ group_id: id });
      this.$router.push({ path: `/group/user/${Encryption.base_enc(id)}/` });
    } else {
      if(!this.user_id){
        this.$alert("로그인후 다시 시도해 주세요.", {
          confirmButtonText: "로그인 하기",
          callback() {
            UserModule.logout();
            that.$router.push({
              path: "/login",
              query: {
                last: Encryption.base_enc(that.$route.fullPath),
              },
            });
          },
        });
      }else{
        let index = ele.findIndex((e:any) => {return e.id === this.user_id})
        if(index!==-1){
          this.$router.push({ path: `/group/user/${Encryption.base_enc(id)}/` });
        }
      }
    }
  }

  @Watch("info.subject", { deep: true })
  watch_subject(res: number) {
    this.info.subject = res;
    this.info.chapter = "";
    this.subject.forEach((item: any) => {
      if (item.id === res) {
        this.options = item.chapter;
      }
    });
  }

  async submit() {
    try {
      await (this.$refs["form"] as ElForm).validate();
      this._loading = true;
      const res:any = await api_group
        .create_group(this.info as any as create_group)
        .finally(() => {
          this._loading = false;
        });
        await api_group.add_group({ group_id: res.id });
      this.$router.push({ path: `/group/user/${Encryption.base_enc(res.id)}/` });
    } catch (e) {
      this.$message.error(
        "입력하신 정보가 정확하지 않습니다. 다시 한번 확인해 주세요."
      );
    }
  }

  get user_id() {
    return UserModule.info?.id;
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
